import mapStyles from '../styles/mapStyles'
import { GoogleMap, Marker, HeatmapLayer } from '@react-google-maps/api'
import React, { useCallback } from 'react'
import myLocationIcon from '../assets/MyLocation.svg'

const mapContainerStyle = {
  width: '100%',
  height: '100vh',
}

const mapContainerStylePrint = {
  width: '100vw',
  height: '800px',
}

const EncounterMap = React.forwardRef((props, ref) => {
  // Access the map anywhere in the code without causing re-renders
  const handleMapLoad = useCallback((map) => {
    props.onMapLoaded(map)
  }, [])

  const isSelectedEncounter = (encounter) => {
    return props.selectedEncounter?.id === encounter.id
  }

  return (
    <GoogleMap
      ref={ref}
      mapContainerStyle={props.print === true ? mapContainerStylePrint : mapContainerStyle}
      zoom={15}
      center={props.location}
      options={{
        styles: mapStyles,
        streetViewControl: false,
        fullscreenControl: false,
        gestureHandling: 'greedy',
      }}
      onClick={props.onMapClick}
      onLoad={handleMapLoad}
    >
      {props.heatMapEnabled && (
        <HeatmapLayer
          data={props.encounters.map((encounter) => {
            return new window.google.maps.LatLng(parseFloat(encounter.latitude), parseFloat(encounter.longitude))
          })}
          onLoad={() => {}}
          onUnmount={() => {}}
          options={{ radius: 50 }}
        />
      )}
      {props.currentLocation && (
        <Marker
          key={'CurrentLocation'}
          position={{
            lat: parseFloat(props.currentLocation.lat),
            lng: parseFloat(props.currentLocation.lng),
          }}
          icon={{
            url: myLocationIcon,
            scaledSize: { width: 24, height: 24 },
            anchor: { x: 12, y: 12 },
          }}
        />
      )}
      {props.markersEnabled &&
        props.encounters.map((encounter) => (
          <Marker
            draggable={isSelectedEncounter(encounter)}
            onDragEnd={(e) => {
              encounter.latitude = e.latLng.lat()
              encounter.longitude = e.latLng.lng()
            }}
            label={{
              text: isSelectedEncounter(encounter) ? 'Drag Me' : ' ',
              fontSize: '20px',
              fontWeight: 'bold',
              className: 'pb-4 pl-4',
            }}
            key={encounter.id || 'new'}
            position={{ lat: parseFloat(encounter.latitude), lng: parseFloat(encounter.longitude) }}
            icon={{
              path:
                'M25.015 2.4c-7.8 0-14.121 6.204-14.121 13.854 0 7.652 14.121 32.746 14.121 32.746s14.122-25.094 14.122-32.746c0-7.65-6.325-13.854-14.122-13.854z',
              strokeColor: 'black',
              strokeWeight: 2,
              strokeOpacity: !props.selectedEncounter || isSelectedEncounter(encounter) ? 1 : 0.5,
              fillColor: encounter.encounterType.color || 'red',
              fillOpacity: !props.selectedEncounter || isSelectedEncounter(encounter) ? 1 : 0.5,
              height: 16,
              width: 16,
              scale: 0.7,
              anchor: { x: 25, y: 50 },
            }}
            onClick={() => {
              props.onMarkerClick(encounter)
            }}
          />
        ))}
    </GoogleMap>
  )
})
export default EncounterMap
