import React from 'react'
import { Button } from 'react-bootstrap'
import { faBurn } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HeatMapToggle = (props) => {
  return (
    <div className="text-right float-right mr-2">
      <Button onClick={props.toggleHeatMap} variant="primary" data-toggle="tooltip" title="Toggle Heat Map">
        <FontAwesomeIcon icon={faBurn} color={props.heatMapEnabled ? 'red' : null} />
      </Button>
    </div>
  )
}

export default HeatMapToggle
