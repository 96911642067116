import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useUsersService } from '../services/UsersService'

function UserCheckboxList(props) {
  const usersService = useUsersService()
  const [users, setUsers] = useState(null)

  useEffect(() => {
    const fetchUsers = async () => await usersService.get()
    fetchUsers().then((users) => setUsers(users))
  }, [])

  const handleUpdateSelected = (userId) => {
    let selectedUsers = [...props.selected]
    if (selectedUsers.includes(userId)) {
      selectedUsers = selectedUsers.filter((existingUserId) => existingUserId !== userId)
    } else {
      selectedUsers.push(userId)
    }
    props.onUpdateSelected(selectedUsers)
  }

  return (
    <Form.Group>
      <Form.Label>Users</Form.Label>
      {users?.map((user) => (
        <div key={user.id} onClick={() => handleUpdateSelected(user.id)}>
          <Form.Check
            type="checkbox"
            label={user.name}
            className="ml-1 py-1"
            checked={props.selected.includes(user.id)}
            onChange={() => handleUpdateSelected(user.id)}
          />
        </div>
      ))}
    </Form.Group>
  )
}

export default UserCheckboxList
