import { useApi } from './Api'

export const useEncounterService = () => {
  const server = useApi()

  return {
    get: async (params) => {
      const { data } = await server.get('encounters', { params })
      return data
    },
    getById: async (id) => {
      const { data } = await server.get(`encounters/${id}`)
      return data
    },
    create: async (encounter) => {
      const { data } = await server.post('encounters', encounter)
      return data
    },
    update: async (encounter) => {
      const { data } = await server.put(`encounters/${encounter.id}`, encounter)
      return data
    },
    delete: async (id) => {
      const { status } = await server.delete(`encounters/${id}`)
      return status === 200
    },
  }
}
