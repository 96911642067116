import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { Button, Modal } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import UserCheckboxList from './UserCheckboxList'
import DateRangeSelector from './DateRangeSelector'
import EncounterTypeCheckboxList from './EncounterTypeCheckboxList'

const EncounterMapFilter = (props) => {
  const updateFilters = (value, property) => {
    props.updateFilters({ ...props.filters, [property]: value })
  }

  return (
    <div className="text-right float-right mr-2">
      <Button
        className="mb-2"
        onClick={props.toggleExpanded}
        variant="primary"
        data-toggle="tooltip"
        title="Change Filters"
      >
        <FontAwesomeIcon icon={faFilter} color={props.expanded ? 'Lime' : null} />
      </Button>
      <Modal show={props.expanded} onHide={props.handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DateRangeSelector
            from={props.filters.from}
            to={props.filters.to}
            onUpdateFrom={(date) => updateFilters(date, 'from')}
            onUpdateTo={(date) => updateFilters(date, 'to')}
          />
          <EncounterTypeCheckboxList
            selected={props.filters.encounterTypes}
            onUpdateSelected={(newSelectedEncounterTypes) => updateFilters(newSelectedEncounterTypes, 'encounterTypes')}
          />
          <UserCheckboxList
            selected={props.filters.users}
            onUpdateSelected={(newSelectedUsers) => updateFilters(newSelectedUsers, 'users')}
          />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default EncounterMapFilter
