import { useApi } from './Api'

export const useEncounterTypeService = () => {
  const server = useApi()

  return {
    get: async () => {
      const { data } = await server.get(`encounterTypes/`)
      return data
    },
    getById: async (id) => {
      const { data } = await server.get(`encounterTypes/${id}`)
      return data
    },
    create: async (encounterType) => {
      const { data } = await server.post('encounterTypes', encounterType)
      return data
    },
    update: async (encounterType) => {
      const { data } = await server.put(`encounterTypes/${encounterType.id}`, encounterType)
      return data
    },
    delete: async (id) => {
      const { status } = await server.delete(`encounterTypes/${id}`)
      return status === 200
    },
  }
}
