import { useLoadScript } from '@react-google-maps/api'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Dropdown, Image, Row } from 'react-bootstrap'
import { useEncounterService } from '../services/EncounterService'
import { useEncounterTypeService } from '../services/EncounterTypeService'
import EncounterDetailsPanel from '../components/EncounterDetailsPanel'
import EncounterMap from '../components/EncounterMap'
import EncounterMapFilter from '../components/EncounterMapFilter'
import { format } from 'date-fns'
import NewEncounterButton from '../components/NewEncounterButton'
import HeatMapToggle from '../components/HeatMapToggle'

import HamburgerMenu from '../components/menu/HamburgerMenu'
import BuildReportAction from '../components/menu/BuildReportAction'
import PrintPageAction from '../components/menu/PrintPageAction'
import LogoutAction from '../components/menu/LogoutAction'

import EncounterReportPanel from '../components/EncounterReportPanel'
import { useOktaAuth } from '@okta/okta-react'
import { toast } from 'react-toastify'

const menuStyle = {
  right: 0,
}

const libraries = ['visualization']

const MapPage = () => {
  const mapPageRef = useRef()

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  })

  const { oktaAuth, authState } = useOktaAuth()

  const [currentUser, setCurrentUser] = useState(null)
  const [encounters, setEncounters] = useState([])
  const [selectedEncounter, setSelectedEncounter] = useState(null)
  const [defaultEncounterType, setDefaultEncounterType] = useState(null)
  const [location, setLocation] = useState({ lat: 39.529633, lng: -119.813805 })
  const [currentLocation, setCurrentLocation] = useState(null)
  const [showReportBuilder, setShowReportBuilder] = useState(false)
  const [filterExpanded, setFilterExpanded] = useState(false)
  const [filters, setFilters] = useState({ from: new Date(), to: new Date(), encounterTypes: [], users: [] })
  const [heatMapEnabled, setHeatMapEnabled] = useState(false)
  const [markersEnabled, setMarkersEnabled] = useState(true)
  const [map, setMap] = useState(null)
  const [print, setPrint] = useState(false)

  const encounterService = useEncounterService()
  const encounterTypeService = useEncounterTypeService()

  useEffect(() => {
    initializeCurrentLocation()
  }, [authState, oktaAuth])

  useEffect(() => {
    loadEncounters()
    loadDefaultEncounterType()
    loadCurrentUser()
  }, [filters, authState, oktaAuth])

  const updateCurrentLocation = async () => {
    navigator.geolocation.getCurrentPosition(
      function (location) {
        setCurrentLocation({ lat: location.coords.latitude, lng: location.coords.longitude })
      },
      () => {
        console.error('Failed to update current location.')
      },
      { timeout: 10000, enableHighAccuracy: true }
    )
  }

  const initializeCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(
      function (location) {
        const currentLocation = { lat: location.coords.latitude, lng: location.coords.longitude }
        setLocation(currentLocation)
        setCurrentLocation(currentLocation)
        setInterval(updateCurrentLocation, 2000)
      },
      () => {
        console.error('Failed to initialize current location.  Retrying in 3 seconds.')
        setTimeout(() => initializeCurrentLocation(), 3000)
      },
      { timeout: 10000, enableHighAccuracy: true }
    )
  }

  const loadEncounters = async () => {
    const encounters = await encounterService.get({
      types: filters.encounterTypes,
      users: filters.users,
      start: format(filters.from, 'yyyy-M-d'),
      end: format(filters.to, 'yyyy-M-d'),
    })
    setEncounters(encounters)
  }

  const loadDefaultEncounterType = async () => {
    const encounterTypes = await encounterTypeService.get()
    setDefaultEncounterType(encounterTypes[0])
  }

  const loadCurrentUser = async () => {
    if (authState.isAuthenticated) {
      oktaAuth.getUser().then((info) => {
        setCurrentUser({
          id: info.sub,
          name: info.name,
        })
      })
    }
  }

  const saveEncounter = async (savedEncounter) => {
    const encountersWithoutSaved = savedEncountersOnly().filter((encounter) => encounter.id !== savedEncounter.id)
    setEncounters([...encountersWithoutSaved, await encounterService.update(savedEncounter)])
  }

  const insertEncounter = async (insertedEncounter) => {
    setEncounters([...savedEncountersOnly(), await encounterService.create(insertedEncounter)])
  }

  const handleEncounterDetailSave = async (savedEncounter) => {
    try {
      if (savedEncounter.id) {
        await saveEncounter(savedEncounter)
        toast.success('Record updated')
      } else {
        await insertEncounter(savedEncounter)
        toast.success('Record created')
      }
      setSelectedEncounter(null)
    } catch (e) {
      toast.error('Save failed')
    }
  }

  const handleEncounterDetailDelete = async (deletedEncounter) => {
    try {
      await encounterService.delete(deletedEncounter.id)
      toast.success('Record deleted')
      const encountersWithoutDeleted = savedEncountersOnly().filter((encounter) => encounter.id !== deletedEncounter.id)
      setEncounters(encountersWithoutDeleted)
      setSelectedEncounter(null)
    } catch (e) {
      toast.error('Delete failed')
    }
  }

  const encountersWithoutSelected = () => {
    return savedEncountersOnly().filter((encounter) => encounter.id !== selectedEncounter.id)
  }

  const handleEncounterDetailCancel = async () => {
    if (selectedEncounter.id) {
      setEncounters([...encountersWithoutSelected(), await encounterService.getById(selectedEncounter.id)])
    } else {
      setEncounters(savedEncountersOnly())
    }
    setSelectedEncounter(null)
  }

  const handleUpdateFilters = async (newFilters) => {
    await setFilters(newFilters)
  }

  const handleUpdateDateRange = async (dateRange) => {
    await setFilters({ ...filters, ...dateRange })
  }

  const handleMapClick = async (event) => {
    if (!selectedEncounter) {
      newEncounter({
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      })
    }
  }

  const handleNewEncounter = async () => {
    if (selectedEncounter) {
      setSelectedEncounter(null)
    }
    await newEncounter(currentLocation)
  }

  const newEncounter = async (location) => {
    const newEncounter = {
      latitude: location.lat,
      longitude: location.lng,
      datetime: new Date(),
      encounterType: defaultEncounterType,
      encounterTypeId: defaultEncounterType.id,
      note: '',
      amount: null,
      createdByUserId: currentUser.id,
    }
    setSelectedEncounter(newEncounter)
    setEncounters([...savedEncountersOnly(), newEncounter])
    map.panTo(location)
  }

  const handleMarkerClick = (encounter) => {
    if (!selectedEncounter) {
      setEncounters(savedEncountersOnly())
      setSelectedEncounter(encounter)
      map.panTo({
        lat: +encounter.latitude,
        lng: +encounter.longitude,
      })
    }
  }

  const handleMapLoad = (m) => {
    setMap(m)
  }

  const savedEncountersOnly = () => {
    return encounters.filter((encounter) => encounter.id)
  }

  const toggleHeatMap = () => {
    setHeatMapEnabled(!heatMapEnabled)
    setMarkersEnabled(!markersEnabled)
  }

  const updateEncounter = (updatedEncounter) => {
    setSelectedEncounter(updatedEncounter)
    setEncounters([...encountersWithoutSelected(), updatedEncounter])
  }

  //TODO: Set up loading and error pages
  if (loadError) return 'Error loading maps'
  if (!isLoaded) return 'Loading Maps'

  return (
    <Container fluid>
      <div style={menuStyle} className="m-2 position-absolute sticky-top">
        <HamburgerMenu>
          <BuildReportAction showReportBuilder={() => setShowReportBuilder(true)} />
          <PrintPageAction
            mapRef={mapPageRef.current}
            filters={filters}
            onBeforePrint={() => setPrint(true)}
            onAfterPrint={() => setPrint(false)}
          />
          <Dropdown.Divider />
          <LogoutAction />
        </HamburgerMenu>
        <EncounterMapFilter
          expanded={filterExpanded}
          handleClose={() => setFilterExpanded(false)}
          toggleExpanded={() => setFilterExpanded(!filterExpanded)}
          filters={filters}
          updateFilters={handleUpdateFilters}
        />
        <HeatMapToggle toggleHeatMap={toggleHeatMap} heatMapEnabled={heatMapEnabled} />
        {currentLocation && <NewEncounterButton onClick={handleNewEncounter} />}
      </div>
      <EncounterReportPanel
        show={showReportBuilder}
        handleClose={() => setShowReportBuilder(false)}
        dateRange={filters}
        updateDateRange={handleUpdateDateRange}
      />
      {selectedEncounter && (
        <EncounterDetailsPanel
          currentUserId={currentUser.id}
          encounter={selectedEncounter}
          updateEncounter={updateEncounter}
          onCancel={handleEncounterDetailCancel}
          onSave={handleEncounterDetailSave}
          onDelete={handleEncounterDetailDelete}
        />
      )}
      <Row className="p-0 d-m">
        <Col className="p-0">
          <EncounterMap
            ref={mapPageRef}
            selectedEncounter={selectedEncounter}
            encounters={encounters}
            currentLocation={currentLocation}
            location={location}
            onMapClick={handleMapClick}
            onMarkerClick={handleMarkerClick}
            onMapLoaded={handleMapLoad}
            heatMapEnabled={heatMapEnabled}
            markersEnabled={markersEnabled}
            print={print}
          />
        </Col>
      </Row>
      <Image className="fixed-bottom pl-2 pb-4" src="map-page-logo.png" height="80px" roundedCircle />
    </Container>
  )
}

export default MapPage
