import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'

import { Security } from '@okta/okta-react'

import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css'

import App from './App'
import Api from './services/Api'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { OktaAuth } from '@okta/okta-auth-js'

const oktaConfig = {
  issuer: `${process.env.REACT_APP_OKTA_ORG_URL}oauth2/default`,
  redirectUri: `${window.location.origin}/implicit/callback`,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
}
const oktaAuth = new OktaAuth(oktaConfig)

ReactDOM.render(
  <Router>
    <Security oktaAuth={oktaAuth}>
      <Api>
        <App />
      </Api>
    </Security>
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
