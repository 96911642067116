import React from 'react'
import { Col, Form } from 'react-bootstrap'
import DatePicker from 'react-datepicker'

function DateRangeSelector(props) {
  return (
    <Form.Row>
      <Col>
        <Form.Group>
          <Form.Label>From</Form.Label>
          <div>
            <DatePicker
              className="form-control"
              popperPlacement="end"
              selected={props.from}
              maxDate={props.to}
              onChange={(date) => props.onUpdateFrom(date)}
            />
          </div>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group>
          <Form.Label>To</Form.Label>
          <div>
            <DatePicker
              className="form-control"
              popperPlacement="end"
              selected={props.to}
              minDate={props.from}
              onChange={(date) => props.onUpdateTo(date)}
            />
          </div>
        </Form.Group>
      </Col>
    </Form.Row>
  )
}

export default DateRangeSelector
