import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { useEncounterTypeService } from '../services/EncounterTypeService'

function EncounterTypeCheckboxList(props) {
  const encounterTypeService = useEncounterTypeService()

  const [encounterTypes, setEncounterTypes] = useState(null)

  useEffect(() => {
    const fetchEncounterTypes = async () => await encounterTypeService.get()
    fetchEncounterTypes().then((encounterTypes) => setEncounterTypes(encounterTypes))
  }, [])

  const handleUpdateSelected = (encounterTypeId) => {
    let selectedEncounterTypes = [...props.selected]
    if (selectedEncounterTypes.includes(encounterTypeId)) {
      selectedEncounterTypes = selectedEncounterTypes.filter(
        (existingEncounterTypeId) => existingEncounterTypeId !== encounterTypeId
      )
    } else {
      selectedEncounterTypes.push(encounterTypeId)
    }
    props.onUpdateSelected(selectedEncounterTypes)
  }

  return (
    <Form.Group>
      <Form.Label>Types</Form.Label>
      {encounterTypes?.map((encounterType) => (
        <div key={encounterType.id} onClick={() => handleUpdateSelected(encounterType.id)}>
          <Form.Check
            type="checkbox"
            label={
              <div>
                <span style={{ width: '20px', height: '20px' }}>
                  <svg
                    version="1.2"
                    baseProfile="tiny"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20px"
                    height="20px"
                    viewBox="0 0 50 50"
                    overflow="inherit"
                  >
                    <path
                      fill={encounterType.color}
                      stroke={'black'}
                      strokeWidth={2}
                      d="M25.015 2.4c-7.8 0-14.121 6.204-14.121 13.854 0 7.652 14.121 32.746 14.121 32.746s14.122-25.094 14.122-32.746c0-7.65-6.325-13.854-14.122-13.854z"
                    />
                  </svg>
                </span>
                {encounterType.name}
              </div>
            }
            className="ml-1 py-1"
            checked={props.selected.includes(encounterType.id)}
            onChange={() => handleUpdateSelected(encounterType.id)}
          />
        </div>
      ))}
    </Form.Group>
  )
}

export default EncounterTypeCheckboxList
