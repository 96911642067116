import React, { useEffect, useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import 'react-datepicker/dist/react-datepicker.css'
import { format } from 'date-fns'
import { useReportService } from '../services/ReportService'
import DateRangeSelector from './DateRangeSelector'
import UserCheckboxList from './UserCheckboxList'

const EncounterReportPanel = (props) => {
  const reportService = useReportService()
  const [users, setUsers] = useState([])
  const [location, setLocation] = useState('')

  useEffect(() => {
    setUsers([])
    setLocation('')
  }, [props.show])

  const updateDateRange = (value, property) => {
    props.updateDateRange({ ...props.dateRange, [property]: value })
  }

  const downloadReport = async () => {
    await reportService.get({
      users,
      from: format(props.dateRange.from, 'yyyy-M-d'),
      to: format(props.dateRange.to, 'yyyy-M-d'),
      location: location,
    })
    props.handleClose()
  }

  return (
    <Modal show={props.show} onHide={props.handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Excel Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DateRangeSelector
          from={props.dateRange.from}
          to={props.dateRange.to}
          onUpdateFrom={(date) => updateDateRange(date, 'from')}
          onUpdateTo={(date) => updateDateRange(date, 'to')}
        />
        <UserCheckboxList selected={users} onUpdateSelected={(newSelectedUsers) => setUsers(newSelectedUsers)} />
        <Form.Group>
          <Form.Label>Location Covered</Form.Label>
          <Form.Control type="text" value={location} onChange={(e) => setLocation(e.target.value)} />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={!users.length} className="float-right" variant="primary" onClick={downloadReport}>
          Create
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default EncounterReportPanel
