import React from 'react'
import { Button, Image } from 'react-bootstrap'
import myLocationIcon from '../assets/MyLocationWhite.svg'

const buttonStyle = {
  display: 'flex',
  justifyContent: 'center',
  width: '38px',
  height: '38px',
}

const NewEncounterButton = (props) => {
  return (
    <div className="text-right float-right mx-2">
      <Button style={buttonStyle} onClick={props.onClick} variant="primary" data-toggle="tooltip" title="Encounter at Current Location">
        <Image src={myLocationIcon} />
      </Button>
    </div>
  )
}

export default NewEncounterButton
