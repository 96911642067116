import { useApi } from './Api'

export const useUsersService = () => {
  const server = useApi()

  return {
    get: async () => {
      const { data } = await server.get('users')
      return data
    },
  }
}
