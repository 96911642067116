import React from 'react'
import { useOktaAuth } from '@okta/okta-react'
import MenuAction from './MenuAction'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

const LogoutAction = (props) => {
  const { oktaAuth } = useOktaAuth()

  const logout = () => {
    oktaAuth.signOut()
  }

  return <MenuAction icon={faSignOutAlt} text="Logout" onClick={logout} />
}

export default LogoutAction
