import { useApi } from './Api'

export const useReportService = () => {
  const server = useApi()

  return {
    get: async (params) => {
      const response = await server.get('report', { responseType: 'blob', params })
      let blob = new Blob([response.data])
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = `${params.to}-OTR-outreach-efforts-report.xlsx`
      a.click()
    },
  }
}
