import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const MenuAction = (props) => {
  return (
    <Dropdown.Item className="px-3" onClick={props.onClick}>
      <FontAwesomeIcon icon={props.icon} className="pr-2" fixedWidth />
      {props.text}
    </Dropdown.Item>
  )
}

export default MenuAction
