import './App.css'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { LoginCallback, SecureRoute } from '@okta/okta-react'
import MapPage from './pages/MapPage'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  return (
    <div>
      <Switch>
        <Route path="/implicit/callback" component={LoginCallback} />
        <SecureRoute path="/" component={() => <MapPage />} />
      </Switch>
      <ToastContainer position={'bottom-center'} closeOnClick theme={'colored'} />
    </div>
  )
}

export default App
