import React, { useEffect, useState } from 'react'
import { useEncounterTypeService } from '../services/EncounterTypeService'
import { formatISO, parseISO } from 'date-fns'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { Col } from 'react-bootstrap'
import DatePicker from 'react-datepicker'

const detailStyle = {
  left: 0,
  width: '500px',
  maxWidth: '100vw',
}

const EncounterDetailsPanel = (props) => {
  const encounterTypeService = useEncounterTypeService()
  const [encounterTypes, setEncounterTypes] = useState(null)

  useEffect(() => {
    loadEncounterTypes()
  }, [])

  const loadEncounterTypes = async () => {
    const encounterTypes = await encounterTypeService.get()
    setEncounterTypes(encounterTypes)
  }

  const setEncounterProperty = (value, property) => {
    const updatedEncounter = { ...props.encounter, [property]: value }
    props.updateEncounter(updatedEncounter)
  }

  const setEncounterType = (encounterTypeId) => {
    const encounterType = encounterTypes.find((x) => x.id === parseInt(encounterTypeId))
    props.updateEncounter({ ...props.encounter, encounterTypeId, encounterType })
  }

  const isCreatedByCurrentUser = props.currentUserId === props.encounter.createdByUserId
  const userName = isCreatedByCurrentUser ? 'you' : props.encounter?.createdByUser?.name || 'Unknown'

  return (
    <div style={detailStyle} className="p-0 position-absolute sticky-top">
      <Card className={'p-4'}>
        <h2>{props.encounter.id ? props.encounter.encounterType.name : 'New Encounter'}</h2>
        <Form>
          {props.encounter.id && (
            <Form.Group>
              <Form.Label>
                Added by <b>{userName}</b> on
              </Form.Label>
              <DatePicker
                className={'ml-2 form-control'}
                selected={parseISO(props.encounter.datetime)}
                onChange={(date) => setEncounterProperty(formatISO(date), 'datetime')}
                popperPlacement="bottom-start"
                showTimeSelect
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label>Encounter Type</Form.Label>
            <Form.Control
              as="select"
              value={props.encounter.encounterType.id}
              onChange={(e) => setEncounterType(e.target.value)}
            >
              {encounterTypes?.map((encounterType) => (
                <option key={encounterType.id} value={encounterType.id}>
                  {encounterType.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {props.encounter.encounterType.name.toLowerCase().includes('referral') ? (
            <React.Fragment>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>First name</Form.Label>
                    <Form.Control
                      type="text"
                      value={props.encounter.firstName}
                      onChange={(e) => setEncounterProperty(e.target.value, 'firstName')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Last name</Form.Label>
                    <Form.Control
                      type="text"
                      value={props.encounter.lastName}
                      onChange={(e) => setEncounterProperty(e.target.value, 'lastName')}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Group>
                <Form.Label>Agency referred to</Form.Label>
                <Form.Control
                  type="text"
                  value={props.encounter.agency}
                  onChange={(e) => setEncounterProperty(e.target.value, 'agency')}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Known outcome</Form.Label>
                <Form.Control
                  type="text"
                  value={props.encounter.outcome}
                  onChange={(e) => setEncounterProperty(e.target.value, 'outcome')}
                />
              </Form.Group>
            </React.Fragment>
          ) : (
            <Form.Group>
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="number"
                min={0}
                value={props.encounter.amount}
                onChange={(e) => setEncounterProperty(e.target.value, 'amount')}
              />
            </Form.Group>
          )}

          <Form.Group>
            <Form.Label>Notes</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={props.encounter.note}
              onChange={(e) => setEncounterProperty(e.target.value, 'note')}
            />
          </Form.Group>
          <div className="pb-3 text-secondary font-weight-bold">Drag map marker to change location.</div>
        </Form>
        <div>
          {isCreatedByCurrentUser && props.encounter.id && (
            <Button className="float-left" variant="danger" onClick={() => props.onDelete(props.encounter)}>
              Delete
            </Button>
          )}
          <Button className="float-right" variant="primary" onClick={() => props.onSave(props.encounter)}>
            {props.encounter.id ? 'Update' : 'Create'}
          </Button>
          <Button className="float-right mr-2" variant="secondary" onClick={props.onCancel}>
            Cancel
          </Button>
        </div>
      </Card>
    </div>
  )
}

export default EncounterDetailsPanel
