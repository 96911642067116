import React, { useState, useEffect, useContext } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import axios from 'axios'

const createApi = (authorization) => {
  return axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
      'content-type': 'application/json;charset=UTF-8',
      Authorization: authorization,
    },
  })
}

const ApiContext = React.createContext(createApi(''))
export const useApi = () => useContext(ApiContext)

export const Api = (props) => {
  const { authState } = useOktaAuth()
  const [authorization, setAuthorization] = useState('')

  useEffect(() => {
    if (authState.isAuthenticated) {
      setAuthorization(`bearer ${authState.accessToken.accessToken}`)
    }
  }, [authState])

  const api = createApi(authorization)

  return <ApiContext.Provider value={api}>{props.children}</ApiContext.Provider>
}

export default Api
