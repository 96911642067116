import React from 'react'
import Style from 'style-it'
import { Dropdown } from 'react-bootstrap'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HamburgerMenu = (props) => {
  return (
    <div className="text-right float-right mx-2">
      <Style>{'#hamburger-menu:after { content: none }'}</Style>
      <Dropdown>
        <Dropdown.Toggle variant="primary" id="hamburger-menu">
          <FontAwesomeIcon icon={faBars} />
        </Dropdown.Toggle>
        <Dropdown.Menu>{props.children}</Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default HamburgerMenu
