import React from 'react'
import { format } from 'date-fns'
import { useReactToPrint } from 'react-to-print'
import MenuAction from './MenuAction'
import { faPrint } from '@fortawesome/free-solid-svg-icons'

const PrintPageAction = (props) => {
  const getPageTitle = () => {
    const fromDate = format(props.filters.from, 'yyyyMMdd')
    const toDate = format(props.filters.to, 'yyyyMMdd')
    return `OTR_Outreach_Encounters_${fromDate}-${toDate}`
  }

  const printPage = useReactToPrint({
    bodyClass: 'print-map',
    // Give an arbitrary delay before print to allow react to refresh changes to dom
    // The standard style causes strange issues when printing so a custom style is set in props.onBeforePrint()
    onBeforeGetContent: () => {
      props.onBeforePrint()
      return new Promise(function (resolve) {
        setTimeout(resolve, 10)
      })
    },
    onAfterPrint: props.onAfterPrint,
    pageStyle: '@page { size: auto; font-size: 20px;  margin: 10mm; margin-top: 20mm }',
    documentTitle: getPageTitle(),
    content: () => props.mapRef,
  })

  return <MenuAction icon={faPrint} text="Print Screen" onClick={printPage} />
}

export default PrintPageAction
